:root {
  --background-dark: #0a0a12;
  --card-background: #12121a;
  --text-primary: #e0e0ff;
  --text-secondary: #a0a0c0;
  --text-muted: #6a6a8c;
  --border-color: #2a2a3a;

  /* Accent colors */
  --accent-blue: #00ccff;
  --accent-purple: #9933ff;
  --accent-pink: #ff33cc;
  --accent-green: #33ff99;
  --accent-red: #ff5370;
  --accent-orange: #ff9e7a;

  /* Gradients */
  --gradient-purple-blue: linear-gradient(90deg, var(--accent-purple), var(--accent-blue));
  --gradient-red-blue: linear-gradient(90deg, var(--accent-red), var(--accent-blue));
  --gradient-purple-orange: linear-gradient(90deg, var(--accent-purple), var(--accent-orange));
  --gradient-blue-red: linear-gradient(90deg, var(--accent-blue), var(--accent-red));
}

.promote-header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.promote-view-toggle {
  display: flex;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
}

.promote-view-toggle-btn {
  background: transparent;
  border: none;
  padding: 8px 12px;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.promote-view-toggle-btn.active {
  background-color: rgba(153, 51, 255, 0.2);
  color: var(--accent-purple);
}

.promote-create-promo-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--accent-purple);
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 17px;
}

.promote-create-promo-btn:hover {
  background-color: #8a2be2;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(153, 51, 255, 0.3);
}

/* Promo Grid Styles */
.promote-promo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
  margin-top: 25px;
}

.promote-promo-grid.promote-list-view {
  grid-template-columns: 1fr;
}

.promote-promo-card {
  background-color: var(--card-background);
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
}

.promote-promo-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
}

.promote-promo-card.purple-blue::before {
  background: var(--gradient-purple-blue);
}

.promote-promo-card.red-blue::before {
  background: var(--gradient-red-blue);
}

.promote-promo-card.purple-orange::before {
  background: var(--gradient-purple-orange);
}

.promote-promo-card.blue-red::before {
  background: var(--gradient-blue-red);
}

.promote-promo-header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.promote-promo-type {
  font-size: 14px;
  color: var(--text-secondary);
}

.promote-promo-status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.promote-promo-status span {
  font-size: 14px;
  color: var(--text-secondary);
}

.promote-status-toggle {
  width: 40px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.promote-status-toggle.active {
  background-color: rgba(153, 51, 255, 0.5);
}

.promote-toggle-handle {
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease;
}

.promote-status-toggle.active .promote-toggle-handle {
  transform: translateX(20px);
}

.promote-promo-stats {
  display: flex;
  justify-content: space-around;
  padding: 20px 16px;
}

.promote-stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.promote-stat-icon {
  color: var(--text-secondary);
  width: 24px;
  height: 24px;
}

.promote-stat-value {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-primary);
}

.promote-stat-label {
  font-size: 12px;
  color: var(--text-secondary);
}

.promote-promo-footer {
  padding: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.promote-promo-date {
  font-size: 12px;
  color: var(--text-muted);
}

.promote-title-container {
  padding-bottom: 25px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .promote-header-actions {
    width: 100%;
    justify-content: space-between;
  }

  .promote-promo-grid {
    grid-template-columns: 1fr;
  }

  .promote-title-container {
    flex-direction: column;
    gap: 10px;
    padding-bottom: 25px;
  }
}

.promote-raw-wrapper {
  padding: 30px 45px 15px 25px;
}